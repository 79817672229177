body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  touch-action: manipulation;
}

html,
body,
#root {
  height: 100%;
}

/*  */
/* inputs */
/*  */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

/*  */
/* buttons, inputs */
/*  */
a, div, button:focus, input, .w-button {
  outline: 0;
}

/*  */
/* Toast Styles */
/*  */
.Toastify__toast-container {
  z-index: 999999;
}

.toast {
  min-height: 50px !important;
  background-color: #442c79 !important;
  color: #FFF !important;
}

.toast.Toastify__toast--warning {
  background-color: #DB0C41 !important;
}

.MuiTable-root thead tr th span {
  align-items: center;
  user-select: none;
}

/*  */
/* Map Styles */
/*  */
.map-preview-wrapper {
  height: 700px;
  width: 100%;
}

.map-wrapper {
  height: inherit;
  width: inherit;
  position: relative;
}

.map-wrapper.banner {
  height: calc(100% - 50px);
}

.app-banner {
  background: #fd4703;
  color: #ffffff;
  font-size: 16px;
  height: 50px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.msg-wrapper {
  display: flex;
}

@media screen and (max-width: 750px) {
  .map-wrapper.banner {
    height: calc(100% - 60px);
  } 
  .app-banner {
    height: 60px;
    padding: 10px;
  }
}

.map-wrapper * {
  font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.map-wrapper button {
  outline: none !important;
  background: none;
  color: black;
  width: 100%;
  text-transform: none;
  font-style: normal;
  border: none;
  overflow: hidden;
  cursor: pointer;
}

.map-header-text {
  user-select: none;
  font-size: 16px;
  font-weight: 500;
}

.map-header-text.selected {
  color: #52A300;
  cursor: pointer;
}

#map-container {
  height: 100%;
  width: 100%;
  position: absolute;
}

#map-container-mobile,
.map-wrapper-mobile {
  height: 325px;
  width: 100%;
}

.map-wrapper-mobile {
  position: relative;
}

.map-wrapper-mobile ~ .map-overlay-hidden,
.map-wrapper-mobile .map-hide-button {
  display: none;
  transform: translate3d(0,0,0);
}

.map-wrapper-mobile:not(.hidden) .map-hide-button {
  display: block;
  height: 31px;
  position: absolute;
  top: 14px;
  left: 10px;
  cursor: pointer;
}

.map-wrapper-mobile.hidden {
  visibility: hidden;
  height: 0px;
}

.map-wrapper-mobile.hidden ~ .map-overlay-hidden {
  display: block;
  background: url("../images/map-overlay.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 70px;
}

.map-wrapper-mobile.hidden ~ .map-overlay-hidden .map-show-button {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-wrapper-mobile.hidden ~ .map-overlay-hidden .map-show-button svg {
  cursor: pointer;
}

#map-container-mobile .gmnoprint,
#map-container-mobile .gmnoscreen,
#map-container-mobile img[src^="https://maps.gstatic.com/mapfiles"],
#map-container-mobile .gm-style-iw-a {
  transform: translate3d(0,0,0) !important;
}

.map-search-container {
  position: absolute;
  top: 0;
  bottom: 0;
  background: hsla(0, 0%, 100%, .95);
  height: 100%;
  padding-top: 15px;
  top: 0;
  left: 20px;
  width: 360px;
 -ms-overflow-style: none;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-width: 750px) {
  .map-search-container {
     width: 100%;
     left: 0;
  }
}

.map-search-form-container,
.map-layer-container,
.map-availability-container,
.map-picker-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 0 10px;
  position: relative;
}

.map-layer-container {
  margin-bottom: 5px;
}

.map-picker-container {
  margin-bottom: 10px;
}

.map-dropdown-container {
  position: relative;
  z-index: 10;
  transform: translate3d(0,0,0);
}

.map-search-input {
  font-size: 13px;
  height: 48px;
  color: #666;
  background: transparent;
  border: 1px solid #bbb;
  border-radius: 2px;
  display: block;
  width: 100%;
  padding: 8px 79px 8px 8px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02);
}

.map-search-button-container {
  position: absolute;
  right: 56px;
  top: 0;
}

.map-search-loading-container {
  top: 14px;
  position: absolute;
  right: 10px;
}

.map-search-button-container::after {
  content: "";
  position: absolute;
  right: 0;
  top: 10px;
  border-left: 1px solid #ddd;
  height: 28px;
}

.map-clear-button-container {
  position: absolute;
  right: 10px;
  top: 0;
}

.map-clear-nearby-button {
  cursor: initial !important;
  position: absolute;
  right: 8px;
  top: 10px;
  width: 71px  !important;
  height: 28px;
  border-radius: 14px;
  background: #9b9b9b !important;
}

.map-clear-nearby-button::before {
  content: '';
  pointer-events: none;
  position: absolute;
  z-index: 6;
  top: 2px;
  width: 20px;
  height: 24px;
  background: linear-gradient(to right,rgba(255,255,255,0),rgba(255,255,255,1));
  right: 71px;
}

.map-clear-nearby-button .marker {
  display: block;
  background-image: url("../images/clear-nearby.png");
  background-size: 32px 16px;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 10px;
  top: 7px;
  cursor: pointer;
}

.map-clear-nearby-button .clear {
  display: block;
  background-image: url("../images/clear-nearby.png");
  background-size: 32px 16px;
  background-position: -16px 0;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 12px;
  top: 7px;
  cursor: pointer;
}

.map-search-button,
.map-clear-button  {
  background: transparent;
  border: 0;
  margin: 0;
  outline: 0 ;
  overflow: visible;
  display: block;
  cursor: initial !important;
}

.map-search-button {
  padding: 12px 12px 12px 12px;
}

.map-clear-button {
  padding: 12px 15px 12px 12px;
  cursor: initial;
}

.map-search-button::before {
  content: '';
  display: block;
  width: 22px;
  height: 24px;
  background: url("../images/location-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.map-clear-button::before {
  content: '';
  display: block;
  width: 22px;
  height: 24px;
  background-image: url("../images/clear.png");
  cursor: pointer;
}

.map-layer-button-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

button.map-layer-button {
  user-select: none;
  font-size: 14px;
  width: auto;
  border-radius: 2px;
  padding: 5px;
  margin: 0 10px 10px 0;
  text-align: center;
  border: 1px solid #D2D2D2;
}

button.map-layer-button.selected {
  color: #fff !important;
  background-color: #52A300;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02);
  border: 1px solid #52A300;
}

button.map-layer-button:first-child {
 margin-left: 0 !important;
}

button.map-layer-button:last-child {
  margin-right: 0 !important;
}

.map-availability-button-container {
  display: flex;
  border-radius: 2px;
  margin-top: 10px;
}

button.map-availability-button {
  user-select: none;
  font-size: 14px;
  padding: 10px 0;
  text-align: center;
  background-color: #FFF;
  border-top: 1px solid #bbb;
  border-right: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
}

button.map-availability-button.selected {
  color: #fff;
  background-color: #52A300;
}

button.map-availability-button:first-child {
  border-radius: 2px 0 0 2px;
  border-left: 1px solid #bbb;
}

button.map-availability-button:last-child {
  border-radius: 0 2px 2px 0;
}

.map-picker-button-container {
  display: flex;
  flex-wrap: wrap;
}

.map-picker-button {
  font-size: 12px;
  padding: 6px 10px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.43);
  border-radius: 100px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 8px;
  cursor: pointer;
  user-select: none;
}

.map-picker-button svg {
  font-size: 15px;
  vertical-align: middle;
}

.map-picker-button.active,
.map-picker-button.hoursActive {
  border: 1px solid #52A300;
  color: #52A300;
}

.map-picker-button.selected {
  border: 1px solid #52A300;
  background: #52A300;
  color: #fff;
}

.map-picker-button.active::after,
.map-picker-button.hoursActive::after {
  filter: invert(54%) sepia(74%) saturate(2993%) hue-rotate(55deg) brightness(93%) contrast(103%);
}

.map-picker-button.availability::after {
  transform: rotate(180deg);
  vertical-align: bottom;
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  background-image: url("../images/dropdown-arrow.svg");
  background-size: 10px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.map-picker-button.availability.active::after {
  vertical-align: top;
  transform: none;
}

.map-picker-button.filters::after {
  vertical-align: middle;
  content: '';
  display: inline-block;
  width: 15px;
  height: 10px;
  margin-left: 5px;
  background-image: url("../images/picker.svg");
  background-size: 15px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.map-filter-button-container {
  z-index: 10;
  display: none;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  width: 100%;
  padding: 8px 4px 10px;
  box-shadow: 0px 22px 22px rgba(0, 0, 0, 0.08), 0px 9.19107px 9.19107px rgba(0, 0, 0, 0.0575083), 0px 4.91399px 4.91399px rgba(0, 0, 0, 0.0476886), 0px 2.75474px 2.75474px rgba(0, 0, 0, 0.04), 0px 1.46302px 1.46302px rgba(0, 0, 0, 0.0323114), 0px 0.608796px 0.608796px rgba(0, 0, 0, 0.0224916);
  border-top: 1px solid rgba(196, 196, 196, 0.5);
}

.map-filter-button-container.active {
  display: flex;
}

.map-filter-button {
  font-size: 12px;
  padding: 6px;
  background: rgba(241, 241, 241, 0.9);
  border-radius: 36px;
  margin: 8px 4px;
  cursor: pointer;
  user-select: none;
}

.map-filter-button svg {
  font-size: 10px;
  transform: scale(1.5);
  color: #52A300;
}

.map-availability-dropdown-container {
  user-select: none;
  z-index: 10;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  width: 100%;
  padding: 10px;
  box-shadow: 0px 22px 22px rgba(0, 0, 0, 0.08), 0px 9.19107px 9.19107px rgba(0, 0, 0, 0.0575083), 0px 4.91399px 4.91399px rgba(0, 0, 0, 0.0476886), 0px 2.75474px 2.75474px rgba(0, 0, 0, 0.04), 0px 1.46302px 1.46302px rgba(0, 0, 0, 0.0323114), 0px 0.608796px 0.608796px rgba(0, 0, 0, 0.0224916);
  border-top: 1px solid rgba(196, 196, 196, 0.5);
}

.map-availability-dropdown-container.active {
  display: block;
}

.map-availability-dropdown-container-header {
  font-size: 12px;
  margin-bottom: 12px;
}

.map-availability-dropdown {
  cursor: pointer;
}

.map-filter-reset-button {
  display: flex;
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
  color: #6D6D6D !important;
  width: 50px !important;
  margin-left: auto;
  justify-content: flex-end;
  user-select: none;
}

.map-filter-reset-button:disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: initial;
}

.map-results-container {
  display: flex;
  padding: 10px;
  font-size: 20px;
  background-color: #F3F3F3;
}

.map-results-button-container {
  user-select: none;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
}

.results-list {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #F3F3F3;
}

.results-list * {
  font-family: "Raleway", "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.results-item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 15px 0;
  background-color: #FFF;
  margin-bottom: 10px;
  cursor: pointer;
}

.results-item.details {
  cursor: initial;
}

.results-item:last-of-type {
  margin-bottom: 0px;
}

.results-list.no-results {
  background-color: transparent;
}

.results-item.no-results {
  cursor: initial;
  background-color: transparent;
}

.results-item.no-results div {
  display: flex;
  place-content: center;
  font-size: 16px;
  margin: 50px 0px;
  user-select: none;
}

.results-title {
  display: flex;
  font-size: 16px;
  margin-bottom: 8px;
  padding: 0 12px;
  color: #253D5D;
}

.results-info-wrapper {
  user-select: none;
  display: flex;
  font-size: 10px;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.5);
}

.results-info-wrapper.first {
  flex-wrap: wrap;
  color: rgba(37, 61, 93, 0.7);
}

.results-info-item {
  display: flex;
  margin-right: 10px;
  margin-bottom: 8px;
}

.results-info-item.fill-line {
  width: 100%;
}

.results-info-distance {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.9);
}

.info-dot {
  transform: scale(2);
}

.results-detail-wrapper {
  user-select: none;
  display: flex;
  font-size: 12px;
  color: #000000;
  border-top: 1px solid #D8D8D8;
  padding: 10px 12px;
  margin-top: 10px;
}

.results-detail-wrapper ~ .results-detail-wrapper {
  margin-top: 0;
}

.results-detail-wrapper * {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.results-detail-item {
  display: flex;
  align-items: baseline;
}

.results-detail-item .phone {
  color: #000000;
}

.results-detail-item .address,
.results-detail-item .description {
  color: #333333;
}

.results-detail-item.hours-wrapper {
  flex-direction: column;
}

.results-detail-hours {
  display: flex;
  margin-bottom: 10px;
}

.results-detail-day {
  width: 85px;
}

.results-detail-hour-warning {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  user-select: none;
  display: flex;
  font-size: 12px;
  color: #000000;
  padding: 10px;
  margin: 0px 12px 12px;
  flex-direction: column;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02);
  border-radius: 2px;
}

.results-detail-hour-warning span {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 600;
}

.map-marker * {
  font-family: "Raleway", "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-size: 10px;
}

.map-marker-title {
  font-size: 14px;
  color: #253D5D;
  margin-bottom: 14px;
}

.map-marker-info-wrapper {
  user-select: none;
  display: flex;
  flex-wrap: wrap;
  font-size: 10px;
  color: rgba(37, 61, 93, 0.7);
  line-height: 14px;
}

.map-marker-info-wrapper.last * {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.map-marker-info-wrapper.last {
  margin-top: 4px;
  color: rgba(0, 0, 0, 0.8);
}

.map-marker-info-item {
  display: flex;
  margin-right: 5px;
  margin-bottom: 8px;
}

.map-marker-share {
  user-select: none;
  margin-top: 8px;
}

.map-marker-share a {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  color: #777777
}

.results-print-info-wrapper {
  display: none;
}

/* The popup bubble styling. */
.popup-bubble {
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: #9b9b9b;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 60px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  opacity: .9;
}

.popup-bubble-anchor {
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 0;
}

.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-60%, 0);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #9b9b9b;
  opacity: .9;
}

.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  width: 200px;
  transform: translate3d(0,0,0);
}

@media print {
  header,
  footer,
  #map-container,
  #map-container-mobile,
  .map-wrapper-mobile,
  .map-overlay-hidden,
  .map-search-form-container,
  .map-layer-container,
  .map-availability-container,
  .map-picker-container,
  .map-dropdown-container,
  .map-results-container,
  .results-item .results-info-wrapper:last-of-type {
    display: none !important;
  }

  .results-item {
    border-bottom: 1px solid #838383;
    margin-bottom: 0;
    padding: 6px 0;
  }

  .results-title {
    font-size: 12px !important;
    margin-bottom: 0;
  }

  .results-info-item {
    margin-bottom: 0;
  }

  .results-print-info-wrapper {
    display: flex;
    font-size: 10px;
    margin-bottom: 3px;
    padding: 0px 12px 0px 12px;
    color: #253D5D;
    flex-direction: column;
  }

  .map-wrapper,
  .map-search-container,
  .results-list {
    overflow: visible;
  }
}